/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/slick-carousel@1.8.1/slick/slick.min.js
 * - /npm/formstone@1.4.2/dist/js/core.min.js
 * - /npm/formstone@1.4.2/dist/js/mediaquery.min.js
 * - /npm/formstone@1.4.2/dist/js/swap.min.js
 * - /npm/formstone@1.4.2/dist/js/tabs.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
